import { InvoicePaidStatus } from '@msdyn365-commerce/retail-proxy';

export interface IInvoiceStatusResources {
    invoiceStatusNone: string;
    invoiceStatusUnpaid: string;
    invoiceStatusPartiallyPaid: string;
    invoiceStatusPaid: string;
}

export const getInvoiceStatusText = (resources: IInvoiceStatusResources, invoiceStatus: InvoicePaidStatus | undefined) => {
    switch (invoiceStatus) {
        case InvoicePaidStatus.None:
            return resources.invoiceStatusNone;
        case InvoicePaidStatus.Unpaid:
            return resources.invoiceStatusUnpaid;
        case InvoicePaidStatus.PartiallyPaid:
            return resources.invoiceStatusPartiallyPaid;
        case InvoicePaidStatus.Paid:
            return resources.invoiceStatusPaid;
        default:
            return '';
    }
};