import { InvoicePaidStatus, OrderInvoice } from '@msdyn365-commerce/retail-proxy';

/**
 * Represents invoice data model.
 */
export class InvoiceModel {
    public id?: string;
    public invoiceDate?: Date;
    public customerAccountNumber?: string;
    public customerName?: string;
    public amount?: number;
    public dueDate?: Date;
    public amountDue?: number;
    public status?: InvoicePaidStatus;
    public currencyCode?: string;

    public isSelected: boolean;

    public constructor(invoice: OrderInvoice) {
        this.id = invoice.Id;
        this.invoiceDate = invoice.InvoiceDate;
        this.customerAccountNumber = invoice.CustomerAccountNumber;
        this.customerName = invoice.CustomerName;
        this.amount = invoice.TotalAmount;
        this.dueDate = invoice.InvoiceDueDate;
        this.amountDue = invoice.AmountBalance;
        this.status = invoice.InvoicePaidStatusValue;
        this.currencyCode = invoice.CurrencyCode;

        this.isSelected = false;
    }

    public get canBePaid(): boolean {
        return this.status !== InvoicePaidStatus.Paid;
    }
}