import { Button } from '@msdyn365-commerce-modules/utilities';
import { IComponent, IComponentProps, ICoreContext, msdyn365Commerce } from '@msdyn365-commerce/core';
import React, { useState } from 'react';
import { InvoiceModel } from '../helpers/invoice-data';
import { payInvoices } from '../helpers/pay-invoices';
import { IInvoiceErrorState } from '../invoices-list';

interface IPayInvoiceData {
    invoice: InvoiceModel;
}

export interface IPayInvoiceResources {
    payInvoiceButtonText: string;
    payInvoiceButtonAriaLabel: string;
}

export interface IPayInvoiceProps extends IComponentProps<IPayInvoiceData> {
    context: ICoreContext;
    className: string;

    resources: IPayInvoiceResources;

    onError?(errorState: IInvoiceErrorState): void;
}

interface IPayInvoiceComponent extends IComponent<IPayInvoiceProps> {
}

const onClick = async (_event: React.MouseEvent<HTMLElement>, props: IPayInvoiceProps, setDisabled: (disabled: boolean) => void): Promise<void> => {
    setDisabled(true);

    const propagateError = (errorState: IInvoiceErrorState): void => {
        if (props.onError) {
            props.onError(errorState);
        }
        setDisabled(false);
    };

    await payInvoices([props.data.invoice], props.context.actionContext, propagateError);
};

const PayInvoiceComponentActions = {
    onClick: onClick
};

const PayInvoice: React.FC<IPayInvoiceProps> = (props: IPayInvoiceProps): JSX.Element => {
    const [disabled, setDisabled] = useState(false);

    const onClickHandler = async (event: React.MouseEvent<HTMLElement>) => {
        await PayInvoiceComponentActions.onClick(event, props, setDisabled);
    };

    return (
        <Button
            className={props.className}
            aria-label={props.resources.payInvoiceButtonAriaLabel}
            onClick={onClickHandler}
            disabled={!props.data.invoice.canBePaid || disabled}
        >
            {props.resources.payInvoiceButtonText}
        </Button>
    );
};

// @ts-ignore
export const PayInvoiceComponent: React.FunctionComponent<IPayInvoiceProps> = msdyn365Commerce.createComponent<IPayInvoiceComponent>(
    'PayInvoiceComponent',
    { component: PayInvoice, ...PayInvoiceComponentActions }
);