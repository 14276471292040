import { ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { getUrlSync, IActionContext } from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { IInvoiceErrorState } from '../invoices-list';
import { InvoiceModel } from './invoice-data';

export type ICartActionFailureReason = 'EMPTYINPUT' | 'CARTACTIONFAILED';
export interface IAddInvoicesToCartFailureResult {
    failureReason: ICartActionFailureReason;
}

export const payInvoices = async (invoices: InvoiceModel[], actionContext: IActionContext, onError: (errorState: IInvoiceErrorState) => void) => {
    const invoicesToAdd: string[] = invoices.filter(invoice => invoice.canBePaid).map(invoice => invoice.id!);

    if (!ArrayExtensions.hasElements(invoicesToAdd)) {
        return onError({  errorMessage: '', errorReason: { failureReason: 'EMPTYINPUT' } });
    }

    const cartState = await getCartState(actionContext);
    const result = await cartState.addInvoicesToCart({invoiceIds: invoicesToAdd});

    if (result.status !== 'SUCCESS') {
        return  onError({
            errorMessage: '',
            errorReason: { failureReason: 'CARTACTIONFAILED'},
            otherError: result.errorDetails?.LocalizedMessage
        });
    }

    const cartUrl = getUrlSync('cart', actionContext);
    if (cartUrl) {
        window?.location.assign(cartUrl);
    }
};