import {Alert} from '@msdyn365-commerce-modules/utilities';
import { IComponent, IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import React from 'react';

export interface IInvoiceErrorProps extends IComponentProps {
    messageHeader: string;
    errorMessage: string;
    otherError?: string;
    showError: boolean;
}

export interface IInvoiceErrorComponent extends IComponent<IInvoiceErrorProps> {
}

export const InvoiceError: React.FC<IInvoiceErrorProps> = ({ messageHeader, errorMessage, otherError, showError }) => {
    const errorMessages: (string | undefined)[] = [];
    errorMessages.push(errorMessage);

    if (otherError) {
        errorMessages.push(otherError);
    }

    return (
        <Alert isOpen={showError && errorMessages.length > 0} color='danger' assertive={true} aria-label={messageHeader} >
            <div className='msc-alert__header' aria-hidden='true'>
                <span className='msi-exclamation-triangle' />
                <span>{messageHeader}</span>
            </div>
            {errorMessages.map((message, index) => {
                return (
                    <div key={index} className='msc-alert__line'>{message}</div>
                );
            })}
        </Alert>
    );
};

// @ts-ignore
export const InvoiceErrorComponent: React.FunctionComponent<IInvoiceErrorProps> = msdyn365Commerce.createComponent<IInvoiceErrorComponent>(
    'InvoiceError',
    { component: InvoiceError }
);