import { ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { Button, ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import { IComponent, IComponentProps, ICoreContext, ITelemetry, msdyn365Commerce } from '@msdyn365-commerce/core';
import React, { useState } from 'react';
import { InvoiceModel } from '../helpers/invoice-data';
import { payInvoices } from '../helpers/pay-invoices';
import { IInvoiceErrorState } from '../invoices-list';

interface IMakePaymentData {
    invoices: InvoiceModel[];
}

export interface IMakePaymentResources {
    makePaymentButtonText: string;
    makePaymentButtonAriaLabel: string;
}

export interface IMakePaymentProps extends IComponentProps<IMakePaymentData> {
    context: ICoreContext;
    itemKey?: string;
    telemetry: ITelemetry;
    moduleId: string;
    moduleTypeName: string;
    telemetryContent?: ITelemetryContent;
    navigationUrl?: string;

    resources: IMakePaymentResources;

    onError?(errorState: IInvoiceErrorState): void;
}

export interface IMakePaymentComponent extends IComponent<IMakePaymentProps> {
}

const onClick = async (_event: React.MouseEvent<HTMLElement>, props: IMakePaymentProps, setDisabled: (disabled: boolean) => void): Promise<void> => {
    setDisabled(true);

    const propagateError = (errorState: IInvoiceErrorState): void => {
        if (props.onError) {
            props.onError(errorState);
        }
        setDisabled(false);
    };

    await payInvoices(props.data.invoices, props.context.actionContext, propagateError);
};

const MakePaymentComponentActions = {
    onClick: onClick
};

const MakePayment: React.FC<IMakePaymentProps> = (input: IMakePaymentProps): JSX.Element => {
    const { data: { invoices } } = input;
    const hasInvoices = ArrayExtensions.hasElements(invoices.filter(invoice => invoice.canBePaid));
    const [disabled, setDisabled] = useState<boolean>(false);

    const onClickHandler = async (event: React.MouseEvent<HTMLElement>) => {
        await MakePaymentComponentActions.onClick(event, input, setDisabled);
    };

    return (
        <Button
            aria-label={input.resources.makePaymentButtonAriaLabel}
            onClick={onClickHandler}
            disabled={!hasInvoices || disabled}
            className={input.id}
        >
            {input.resources.makePaymentButtonText}
        </Button>
    );
};

// @ts-ignore
export const MakePaymentComponent: React.FunctionComponent<IMakePaymentProps> = msdyn365Commerce.createComponent<IMakePaymentComponent>(
    'MakePayment',
    { component: MakePayment }
);