import * as React from 'react';

import { Button, getPayloadObject, getTelemetryAttributes, INodeProps, ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import classnames from 'classnames';
import { InputType } from '../business-organization-list';

export interface IBusinessForm {
    FormWrapper: INodeProps;
    message?: React.ReactNode;
    items: IBusinessFormItem[];
    buttons: React.ReactNode[];
    errorMessage: React.ReactNode[];
}

export interface IBusinessFormItem {
    wrapper: INodeProps;
    key: string;
    label: React.ReactNode;
    errorMessage: React.ReactNode;
    input: React.ReactNode;
}

export interface IBusinessFormInput {
    label: React.ReactNode;
    alert: React.ReactNode;
    input: React.ReactNode;
}

export interface IUserFormItem {
    id: string;
    type: InputType;
    label: string;
    value: string;
    maxChars?: string;
    isRequired: boolean;
    pattern?: string;
    isDisabled?: boolean;
    customClass?: string;
}

export interface IBusinessFormInputProps {
    id?: string;
    type: string;
    value?: string;
    pattern?: string;
    className: string;
    maxLength?: string;
    isRequired: boolean;
    isDisabled?: boolean;
    onChange?(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
}

export const FormInput: React.FC<IBusinessFormInputProps> = ({ id, type, value, pattern, className, maxLength, isRequired, isDisabled, onChange }) =>
       (
        <input
            id={id}
            type={type}
            value={value}
            className={`${className}-input`}
            pattern={pattern !== '' ? pattern : undefined}
            aria-required={isRequired}
            required={isRequired}
            maxLength={GetMaxLength(maxLength)}
            onChange={onChange}
            disabled={isDisabled}
        />
    );

export interface IBusinessFormLabel {
    id: string;
    forId: string;
    className: string;
    text: string;
}

export const FormLabel: React.FC<IBusinessFormLabel> = ({ id, forId, className, text }) => (
    <label id={`${id}_label`} className={`${className}-label`} htmlFor={forId}>
        {text}
    </label>
);

export interface IBusinessFormButton {
    id?: string;
    className: string;
    text: string;
    ariaLabel: string;
    disabled?: boolean;
    telemetryContent?: ITelemetryContent;
    onClick?(event: React.MouseEvent<HTMLElement>): void;
}

export const FormButton: React.FC<IBusinessFormButton> = ({ id, className, text, ariaLabel, telemetryContent, disabled, onClick }) => {
    const payLoad = getPayloadObject('click', telemetryContent!, text);
    const attributes = getTelemetryAttributes(telemetryContent!, payLoad);
    return (
    <Button
        id={id}
        className={className}
        aria-label={ariaLabel}
        disabled={disabled}
        onClick={onClick}
        {...attributes}
    >
        {text}
    </Button>
    );
};

export interface IBusinessFormErrorProps {
    id?: string;
    className: string;
    type?: string;
    message?: string;
}

export const FormFieldError: React.FC<IBusinessFormErrorProps> = ({ id, className, type = 'page', message }) => {
      const errorClassName = `${className}__${type}-error`;

      return (
        <div id={id} className={classnames(errorClassName, 'error', `${type}Level`)} role='alert' aria-live='assertive' aria-hidden='true'>
            {
                <p className={`${errorClassName}-text`}>
                    {message}
                </p>
            }
        </div>
    );

};

const GetMaxLength = (maxLength?: string): number | undefined => {
    if (maxLength) {
        const parsedMaxLength = parseInt(maxLength, 10);
        if (!isNaN(parsedMaxLength)) {
            return parsedMaxLength;
        }
    }

    return undefined;
};