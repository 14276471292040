import React from 'react';

import { EnumExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { Button, Popover } from '@msdyn365-commerce-modules/utilities';
import { IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import { IInvoiceStatusResources } from '../helpers/invoice-status';

export interface IInvoicesFilterResources extends IInvoiceStatusResources {
    invoicesFilterShowAll: string;
}

export interface IInvoicesFilterProps extends IComponentProps {
    className: string;
    disabled: boolean;

    resources: IInvoicesFilterResources;

    currentState: InvoicesFilterState;
    onFilterStateChanged(state: InvoicesFilterState): void;
}

export enum InvoicesFilterState {
    ShowAll,
    Paid,
    PartiallyPaid,
    Unpaid
}

const getInvoicesFilterStateResources = (filterState: InvoicesFilterState, resources: IInvoicesFilterResources): string => {
    switch (filterState) {
        case InvoicesFilterState.ShowAll:
            return resources.invoicesFilterShowAll;
        case InvoicesFilterState.Paid:
            return resources.invoiceStatusPaid;
        case InvoicesFilterState.PartiallyPaid:
            return resources.invoiceStatusPartiallyPaid;
        case InvoicesFilterState.Unpaid:
            return resources.invoiceStatusUnpaid;
        default:
            throw new Error('Failed to retrieve resource description for unknown invoices filter state.');
    }
};

const renderFilterStateItem = (
    filterState: InvoicesFilterState,
    resources: IInvoicesFilterResources,
    className: string,
    onStateClick: (filterState: InvoicesFilterState) => void): React.ReactNode => {

    const stateStringRepresentation = InvoicesFilterState[filterState];

    const onClick = () => {
        return onStateClick(filterState);
    };

    return (
        <a role='button' className={`${className}__item__${stateStringRepresentation}`} onClick={onClick}>
            {getInvoicesFilterStateResources(filterState, resources)}
        </a>
    );
};

const InvoicesFilter: React.FC<IInvoicesFilterProps> = (props: IInvoicesFilterProps) => {
    const popoverRef = React.createRef<HTMLButtonElement>();
    const [popoverState, setPopoverState] = React.useState(false);
    const togglePopover = () => {
        setPopoverState(!popoverState);
    };

    const [filterState, setFilterState] = React.useState(props.currentState);

    const onFilterStateClick = (clickedState: InvoicesFilterState) => {
        setFilterState(clickedState);
        setPopoverState(false);
        props.onFilterStateChanged(clickedState);
    };

    return (
        <div className={props.className}>
            <Button
                className={`${props.className}__expand-filter-button`}
                innerRef={popoverRef}
                aria-expanded={popoverState}
                aria-describedby={props.className}
                onClick={togglePopover}
                disabled={props.disabled}
            >
                {getInvoicesFilterStateResources(filterState, props.resources)}
            </Button>
            <Popover
                id={props.className}
                placement={'bottom-end'}
                isOpen={popoverState}
                target={popoverRef}
                toggle={togglePopover}
            >
                {
                    EnumExtensions.getEnumValues<InvoicesFilterState>(InvoicesFilterState).map(state => {
                        return renderFilterStateItem(state, props.resources, props.className, onFilterStateClick);
                    })
                }
            </Popover>
        </div>
    );
};

// @ts-ignore
export const InvoicesFilterComponent: React.FunctionComponent<IInvoicesFilterProps> = msdyn365Commerce.createComponent<IInvoicesFilterProps>(
    'InvoicesFilterComponent',
    { component: InvoicesFilter }
);