import { IComponent, IComponentProps, ICoreContext, msdyn365Commerce } from '@msdyn365-commerce/core';
import classnames from 'classnames';
import React from 'react';

export interface IInvoicesTableHeaderResources {
    invoicesText: string;
    invoiceNumberText: string;
    invoiceDateMobileText: string;
    invoiceDateText: string;
    invoiceUsersText: string;
    invoiceAmountText: string;
    invoiceDueDateText: string;
    invoiceAmountDueText: string;
    invoiceStatusText: string;
    invoiceActionsText: string;
}

export interface IInvoicesTableHeaderProps extends IComponentProps {
    context: ICoreContext;
    className: string;

    isSelectedAll: boolean;
    isMobile: boolean;
    isAdmin: boolean;

    resources: IInvoicesTableHeaderResources;

    onSelectAll(isSelected: boolean): void;
}

export interface IInvoicesTableHeaderComponent extends IComponent<IInvoicesTableHeaderProps> {
}

const getDesktopHeaderCells = (props: IInvoicesTableHeaderProps, rowClassName: string, switchCheckedState: () => void) => {
    const { resources } = props;
    return (
        <>
            <th>
                <label className={classnames(`${rowClassName}__checkbox-container`, 'checkbox-container')}>
                    <input
                        className={classnames(`${rowClassName}__checkbox-input`, 'checkbox-input')}
                        type='checkbox'
                        aria-checked={props.isSelectedAll}
                        checked={props.isSelectedAll}
                        onChange={switchCheckedState}
                    />
                    <span className={classnames(`${rowClassName}__checkmark`, 'checkmark')}/>
                </label>
            </th>
            <th>{resources.invoiceNumberText}</th>
            <th>{resources.invoiceDateText}</th>
            { props.isAdmin ? <th>{resources.invoiceUsersText}</th> : null }
            <th>{resources.invoiceAmountText}</th>
            <th>{resources.invoiceDueDateText}</th>
            <th>{resources.invoiceAmountDueText}</th>
            <th>{resources.invoiceStatusText}</th>
            <th>{resources.invoiceActionsText}</th>
        </>
    );
};

const getMobileHeaderCells = (props: IInvoicesTableHeaderProps, rowClassName: string, switchCheckedState: () => void) => {
    const { resources } = props;
    return (
        <>
            <th>
                <label className={classnames(`${rowClassName}__checkbox-container`, 'checkbox-container')}>
                    <input
                        className={classnames(`${rowClassName}__checkbox-input`, 'checkbox-input')}
                        type='checkbox'
                        aria-checked={props.isSelectedAll}
                        checked={props.isSelectedAll}
                        onChange={switchCheckedState}
                    />
                    <span className={classnames(`${rowClassName}__checkmark`, 'checkmark')}/>
                </label>
            </th>
            <th>{resources.invoicesText}</th>
            <th>{resources.invoiceDateMobileText}</th>
            <th/> {/* Actions */}
        </>
    );
};

const InvoicesTableHeader: React.FC<IInvoicesTableHeaderProps> = (props: IInvoicesTableHeaderProps): JSX.Element => {
    const rowClassName = `${props.className}__row`;

    const switchCheckedState = () => {
        props.onSelectAll(!props.isSelectedAll);
    };

    let headerCells: JSX.Element;
    if (props.isMobile) {
        headerCells = getMobileHeaderCells(props, rowClassName, switchCheckedState);
    } else {
        headerCells = getDesktopHeaderCells(props, rowClassName, switchCheckedState);
    }

    return (
        <thead className={props.className}>
            <tr className={rowClassName}>
                {headerCells}
            </tr>
        </thead>
    );
};

// @ts-ignore
export const InvoicesTableHeaderComponent: React.FunctionComponent<IInvoicesTableHeaderProps> = msdyn365Commerce.createComponent<IInvoicesTableHeaderComponent>(
    'InvoicesTableHeaderComponent',
    { component: InvoicesTableHeader }
);